import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Text from 'components/Typography/Text'
import Button from 'components/Button/Button'
import { setAlertSuccessMessage, showFactCheckerPopup, setAlertErrorMessage } from 'actions/alert'
//imgages
import imgFactChecker from 'assets/img/llm/llmfactchecker.png'
import {SEND_FACTCHECKER_FEATURE_REQUEST} from './gql'

const FactCheckerPopupStyle = {
  headerbackground: {
    background: '#283E52',
    padding: '15px 0px',
    '& img': {
      height: '100%',
      maxHeight: 200
    }
  },
  content: {
    padding: '20px 20px',
    '& div': {
    color: '#283E52',
    '& span': {
    color: '#FF9800'
      }
    },
  },
  
}

class FactCheckerPopup extends Component {
    render() {
        const { showFactCheckerPopup, setAlertErrorMessage, setAlertSuccessMessage, show, email, classes } = this.props

        return (<ConfirmationDialog
          style={{padding: 0}}
          open={show}
          cancelText="Got it"
          onCancel={() => {
            showFactCheckerPopup(false)
          }}
          data-unique="dlgFactCheckerPopup"
        >
          <GridContainer>
            <GridItem xs={12} align="center" className={classes.headerbackground}>
              <img src={imgFactChecker} alt="FactCheck" />
            </GridItem>
            <GridItem xs={12} align="center" paddingTop>
              <Text header>Al Trust FactCheck is here!</Text>
              <Text md padding>
                Don't let LLM risks hold you back. FactCheck provides the control and confidence you need to harness the power of LLMs.
                Mitigate risks, like hallucinations, to boost reliability and accuracy of your conversational Al.
                See it in action in the demo below.
              </Text>
              <Text md bold displayInline>FactCheck is now available with your subscription. Each FactCheck is</Text> <Text displayInline bold md style={{ color: '#FF9800' }}>$5 per test.</Text>
            </GridItem>
            <GridItem xs={12} align="center">
              <Text md>Interested? Contact your <b></b>Cyara representative to learn more.</Text>
              <Mutation
                mutation={SEND_FACTCHECKER_FEATURE_REQUEST}
                onCompleted={data => {
                  showFactCheckerPopup(false)
                  setAlertSuccessMessage('Thank you! Your request has been sent to the support team.')
                }}
                onError={error => {
                  showFactCheckerPopup(false)
                  setAlertErrorMessage('error')
                }}
              >
                {(sendFactCheckerFeatureRequestEmail, { loading, error }) => (
                  <Button minWidth small marginBottomTop color="primary" onClick={() => {
                    sendFactCheckerFeatureRequestEmail({ variables: { email } })
                  }} >Notify</Button>
                )}
              </Mutation>
            </GridItem>
          </GridContainer>
        </ConfirmationDialog>)
      }
    }

    const FactCheckerPopupState = connect(
      state => ({
        show: (state.factcheckerpopup && state.factcheckerpopup.show) || false,
        license: state.settings.license,
        email: state.token.user.email
      }),
      { showFactCheckerPopup, setAlertSuccessMessage, setAlertErrorMessage }
    )(withStyles(FactCheckerPopupStyle)(withRouter(FactCheckerPopup)))

    export default FactCheckerPopupState
