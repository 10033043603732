import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemText from 'components/List/ListItem/ListItemText'
import Chip from 'components/Chip/Chip'
import Tooltip from 'components/Tooltip/Tooltip'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import LastChange from 'components/Info/LastChange'
import AvatarImage from 'components/Avatar/AvatarImage'

import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import TestSessionsEmbeddedTable from '../TestSessions/TestSessionsEmbeddedTable.jsx'

import namespaceStyle from 'assets/jss/material-dashboard-react/views/namespaceStyle.jsx'
import { setTableSettings } from 'actions/table'

import { CHATBOTS_WITH_STATUS_PAGINATED_QUERY, CHATBOTS_COUNT_PAGINATED_QUERY } from '../Chatbots/gql'
import { TESTSETS_WITH_STATUS_PAGINATED_QUERY, TESTSETS_COUNT_PAGINATED_QUERY } from '../TestSets/gql'
import { TESTPROJECTS_WITH_STATUS_PAGINATED_QUERY, TESTPROJECTS_COUNT_PAGINATED_QUERY } from '../TestProjects/gql'
import { CRAWLERPROJECTS_PAGINATED_QUERY, CRAWLERPROJECTS_COUNT_PAGINATED_QUERY } from '../CrawlerProjects/gql'

import { hasPermission } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text.jsx'

class Overview extends React.Component {

  renderTestProjectsCard(namespace) {
    const { user, classes } = this.props

    return <Card>
      <CardHeader color="info">
        <Text header>Test Projects</Text>
      </CardHeader>
      <CardBody className={classes.namespaceEmbeddedTable }>
        {hasPermission(user, 'TESTPROJECTS_SELECT') &&
          <ServerSidePagingTable
            gqlQuery={{
              query: TESTPROJECTS_WITH_STATUS_PAGINATED_QUERY,
              variables: { where: { namespace } },
            }}
            gqlCountQuery={{
              query: TESTPROJECTS_COUNT_PAGINATED_QUERY,
              countValue: data => (data && data.testprojectsCount) || 0
            }}
            gqlVariables={(filterString) => filterString && ({ where: { name_contains: filterString } })}
            name={`Namespace_TestProjects_${namespace}`}
            disableHeader
            disablePageSize
            disableFooterIfNotHasMore
            pageSize={5}
            tableHeaderColor="primary"
            tableHead={[
              { name: 'Name', orderByField: 'name', orderByDefault: 'asc', width: 'medium' },
              { name: 'Last Change', orderByField: 'updatedAt' }
            ]}
            tableData={data => data.testprojects && data.testprojects.map(t => [
              {
                value: t.name,
              },
              () => <LastChange {...t} />,
            ])}
          />
        }
      </CardBody>
    </Card>
  }

  renderTestSessionsCard(namespace) {
    const { user,classes } = this.props


    return <Card>
      <CardHeader color="info">
        <Text header>Test Results</Text>
      </CardHeader>
      <CardBody className={classes.namespaceEmbeddedTable }>
        {hasPermission(user, 'TESTSESSIONS_SELECT') &&
          <TestSessionsEmbeddedTable variables={{ namespace }}
            name={`Namespace_TestSessions_${namespace}`}
            disableHeader
            disablePageSize
            disableFooterIfNotHasMore
            pageSize={5}
            columns={{
              name: true,
              lastChange: true,
              namespace: false,
              result: true,
              actions: false
            }}
          />
        }
      </CardBody>
    </Card>
  }

  renderTestSetsCard(namespace) {
    const { user, classes } = this.props

  return <Card>
      <CardHeader color="info">
        <Text header>Test Sets</Text>
      </CardHeader>
      <CardBody className={classes.namespaceEmbeddedTable }>
        {hasPermission(user, 'TESTSETS_SELECT') &&
          <ServerSidePagingTable
            gqlQuery={{
              query: TESTSETS_WITH_STATUS_PAGINATED_QUERY,
              variables: { where: { namespace } }
            }}
            gqlCountQuery={{
              query: TESTSETS_COUNT_PAGINATED_QUERY,
              countValue: data => data.testsetsCount
            }}
            gqlVariables={(filterString) => filterString && ({ where: { name_contains: filterString } })}
            name={`Namespace_TestSets_${namespace}`}
            disableHeader
            disablePageSize
            disableFooterIfNotHasMore
            pageSize={5}
            tableHeaderColor="primary"
            tableHead={[
              { name: 'Name', orderByField: 'name', orderByDefault: 'asc', width: 'medium' },
              { name: 'Last Change', orderByField: 'updatedAt' },
              { name: 'Convos', orderByField: 'statsConvoCount' },
              { name: 'Utterances', orderByField: 'statsUtterancesCount' }
            ]}
            tableData={data => data && data.testsets && data.testsets.map(t => [
              {
                value: t.name,
                href: `/testsets/view/${t.id}/dashboard`,
              },
              () => <LastChange {...t} />,
              () => <Tooltip title={`${t.statsConvoCount || '0'} Convos`} key={'convos'}><Chip variant="convo" label={t.statsConvoCount || 0} /></Tooltip>,
              () => <Tooltip title={`${t.statsUtterancesCount || '0'} Utterances`} key={'utterances'}><Chip variant="utterance" label={t.statsUtterancesCount || 0} /></Tooltip>,
            ])}
          />
        }
      </CardBody>
    </Card>
  }

  renderChatbotsCard(namespace) {
    const { user, classes } = this.props

    return <Card>
      <CardHeader color="info">
        <Text header>Chatbots</Text>
      </CardHeader>
      <CardBody className={classes.namespaceEmbeddedTable }>
        {hasPermission(user, 'CHATBOTS_SELECT') &&
          <ServerSidePagingTable
            gqlQuery={{
              query: CHATBOTS_WITH_STATUS_PAGINATED_QUERY,
              variables: { where: { namespace } }
            }}
            gqlCountQuery={{
              query: CHATBOTS_COUNT_PAGINATED_QUERY,
              countValue: data => data.chatbotsCount
            }}
            gqlVariables={(filterString) => filterString && ({
              where: { name_contains: filterString },
              connectorFilter: filterString
            })}
            name={`Namespace_Chatbots_${namespace}`}
            disableHeader
            disablePageSize
            disableFooterIfNotHasMore
            pageSize={5}
            tableHeaderColor="primary"
            tableHead={[
              { name: 'Name', orderByField: 'name', orderByDefault: 'asc', width: 'medium' },
              { name: 'Last Change', orderByField: 'updatedAt' },
              'Connector / Chatbot Technology'
            ]}
            tableData={data => data && data.chatbots && data.chatbots.map(a => [
              {
                value: a.name,
                href: `/chatbots/view/${a.id}/dashboard`,
              },
              () => <LastChange {...a} />,
              {
                value: <ListItem small>
                  <AvatarImage avatar={a.avatar} containermode={a.containermode} chatbotId={a.id}/>
                  <ListItemText>{a.connector ? (a.connector.description || a.connector.name) : 'Custom Connector'}</ListItemText>
                </ListItem>
              }
            ])}
          />
        }
      </CardBody>
    </Card>
  }

  renderCrawlerProjectsCard(namespace) {
    const { user, license, classes } = this.props

    return license.crawler && <Card>
      <CardHeader color="info">
        <Text header>Crawler Projects</Text>
      </CardHeader>
      <CardBody className={classes.namespaceEmbeddedTable }>
        {hasPermission(user, 'CRAWLERPROJECTS_SELECT') &&
          <ServerSidePagingTable
            gqlQuery={{
              query: CRAWLERPROJECTS_PAGINATED_QUERY,
              variables: { where: { namespace } }
            }}
            gqlCountQuery={{
              query: CRAWLERPROJECTS_COUNT_PAGINATED_QUERY,
              countValue: data => data.crawlerprojectsCount
            }}
            gqlVariables={(filterString) => { return { where: { OR: [ { name_contains: filterString || '' }, { chatbot: { name_contains: filterString || '' } } ] } }}}
            name={`Namespace_CrawlerProjects_${namespace}`}
            disableHeader
            disablePageSize
            disableFooterIfNotHasMore
            pageSize={5}
            tableHeaderColor="primary"
            tableHead={[
              { name: 'Name', orderByField: 'name', orderByDefault: 'asc', width: 'medium' },
              { name: 'Last Change', orderByField: 'updatedAt' }
            ]}
            tableData={data => data && data.crawlerprojects && data.crawlerprojects.map(t => [
              {
                value: t.name,
                href: `/testdatawizards/crawlerprojects/view/${t.id}`,
                width: 250
              },
              () => <LastChange {...t} />
            ])}
          />
        }
      </CardBody>
    </Card>
  }

  render() {
    const namespace = this.props.match.params.namespace

    return (
      <GridContainer>
        <GridItem xs={12} >
          {this.renderTestProjectsCard(namespace)}
          {this.renderTestSessionsCard(namespace)}

        </GridItem>
        <GridItem xs={12} >
          {this.renderTestSetsCard(namespace)}
          {this.renderChatbotsCard(namespace)}
          {this.renderCrawlerProjectsCard(namespace)}
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setTableSettings }
)(withStyles(namespaceStyle)(withRouter(Overview)))
