import {
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor
} from 'assets/jss/material-dashboard-react.jsx'
//import { normalizeReference } from 'markdown-it/lib/common/utils'

const listStyle = theme => ({
  warningTableHeader: {
    color: warningColor,
  },
  primaryTableHeader: {
    color: theme.colors.primary,
  },
  dangerTableHeader: {
    color: dangerColor,
    
  },
  successTableHeader: {
    color: successColor,
  },
  infoTableHeader: {
    color: infoColor,
  },
  roseTableHeader: {
    color: roseColor,
  },
  grayTableHeader: {
    color: grayColor,
  },
  listItemDense: {
    color: theme.colors.primary,
    paddingTop: 12,
    paddingBottom: 12,
    borderLeft: '6px solid transparent',
    '&:hover' :{
      backgroundColor: theme.colors.customListbg,
    },
    '& svg':{
      width: '25px!Important',
    }
  },
 
  listItemSelected:{
    backgroundColor: `${theme.colors.customListbg} !Important`,
    borderLeft: `6px solid ${theme.colors.common.secondary}`,
},
  
  
  listItemGutters: {
    paddingLeft: 12,
    paddingRight: 12,
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 6
    },    
  },

  listItemCompactPadding: {
    paddingLeft: 6,
    paddingRight: 6,
  },

  listItemRoot: {
    paddingTop: 21,
    paddingBottom: 21,
    
    
  },
  listItemSmallRoot: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  listItemIconSmallRoot: {
    marginRight: 0,
  },
  listItemNoPaddingVertical: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listItemNoPaddingBottom: {
    paddingBottom: 0
  },
  SimpleListPrimaryText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  SimpleListIcon: {
    marginRight: 15
  },
  listItemTextSmallPrimary: {
    color: theme.colors.primary,
    fontSize: '13px'
  },
  listItemTextSmallDense: {
    color: theme.colors.primary,
    overflowWrap: 'anywhere',
    fontSize: '13px',
    maxWidth: 215,

  },
  listItemTextSmallSecondary: {
    color: theme.colors.primary50,
    fontSize: '12px'
  },
  listItemAvatarSmallRoot: {
    height: 32,
    width: 32,
  },
  inputFilter: {
    top: '-8px',
    border: '0px !important',
    '&::before,&::after': {
      borderBottom: '0px !important'
    }
  },
  listWrapper: {
    height: 'auto',
    minHeight: '80vh', 
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'scroll!Important'
    },
    position: 'relative',
    '&::-webkit-scrollbar': {
      appearance: 'none',
      width: 7
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'grey',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)'
    }
    
  },
  listBorder: {
    margin: '0 !Important',
  },
  filterBorder: {
    marginLeft: '0px !important',
    marginRight: '0px !important',
    padding: '0 !Important'
  },
  gridpadding: {
    padding: '0 !Important'
  },
  listpadding: {
    padding: 0,
  },
  
  refreshbutton: {
    padding: 0,
    textAlign: 'center',
    '& button' :{
      position: 'relative',
      top: -6,
      '& svg' :{
        height: 21,
        width: 21,
      }
    }
  },
  selectAllCheckboxPosition: {
    paddingLeft: 15,
    paddingRight: 10
  },
  selectCheckboxPosition: {
    paddingRight: 20,
  },
  '@media (max-width: 1280px)': {
    refreshbutton: {
      '& button' :{
        left: 5,
      }
    }
  }
})

export default listStyle
