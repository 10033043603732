import { gql } from 'apollo-boost'
import { deleteListQueryFromCache } from 'helper/cacheHelper'

const LIST_USER_FRAGMENT = gql`
  fragment ListUser on User {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    lastActivityAt
    sys
    name
    email
    passwordUpdatedAt
    passwordExpired
    roles { id name description permissions }
  }
`

export const USER_QUERY = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      ...ListUser
    }
  }
  ${LIST_USER_FRAGMENT}
`

export const USERS_QUERY = gql`
  query UsersQuery {
    users {
      ...ListUser
    }
  }
  ${LIST_USER_FRAGMENT}
`

const LIST_USERROLE_FRAGMENT = gql`
  fragment ListUserRole on UserRole {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    description
    permissions
  }
`

export const USERROLES_QUERY = gql`
  query UserRolesQuery {
    userroles {
      ...ListUserRole
    }
  }
  ${LIST_USERROLE_FRAGMENT}
`

export const USERROLE_QUERY = gql`
  query UserRoleQuery($id: ID!) {
    userrole(id: $id) {
      ...ListUserRole
    }
  }
  ${LIST_USERROLE_FRAGMENT}
`

export const CREATE_USERROLE = gql`
  mutation CreateUserRole($userRole: UserRoleCreateInput!) {
    createUserRole(userRole: $userRole) {
      ...ListUserRole
    }
  }
  ${LIST_USERROLE_FRAGMENT}
`
export const UPDATE_USERROLE = gql`
  mutation UpdateUserRole($id: ID!, $userRole: UserRoleUpdateInput!) {
    updateUserRole(id: $id, userRole: $userRole) {
      ...ListUserRole
    }
  }
  ${LIST_USERROLE_FRAGMENT}
`
export const DELETE_USERROLE = gql`
  mutation DeleteUserRole($id: ID!) {
    deleteUserRole(id: $id)
  }
`

const LIST_NAMESPACEPERMISSION_FRAGMENT = gql`
  fragment ListNamespacePermission on NamespacePermission {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    namespace
    canRead
    canWrite
    userRole { id name }
  }
`

export const NAMESPACEPERMISSIONS_QUERY = gql`
  query NamespacePermissionsQuery {
    namespacepermissions {
      ...ListNamespacePermission
    }
  }
  ${LIST_NAMESPACEPERMISSION_FRAGMENT}
`

export const NAMESPACEPERMISSION_QUERY = gql`
  query NamespacePermissionQuery($id: ID!) {
    namespacepermission(id: $id) {
      ...ListNamespacePermission
    }
  }
  ${LIST_NAMESPACEPERMISSION_FRAGMENT}
`

export const CREATE_NAMESPACEPERMISSION = gql`
  mutation CreateNamespacePermission($permission: NamespacePermissionInput!) {
    createNamespacePermission(permission: $permission) {
      ...ListNamespacePermission
    }
  }
  ${LIST_NAMESPACEPERMISSION_FRAGMENT}
`
export const UPDATE_NAMESPACEPERMISSION = gql`
  mutation UpdateNamespacePermission($id: ID!, $permission: NamespacePermissionInput!) {
    updateNamespacePermission(id: $id, permission: $permission) {
      ...ListNamespacePermission
    }
  }
  ${LIST_NAMESPACEPERMISSION_FRAGMENT}
`
export const DELETE_NAMESPACEPERMISSION = gql`
  mutation DeleteNamespacePermission($id: ID!) {
    deleteNamespacePermission(id: $id)
  }
`



export const CONNECTORFEATURES_FRAGMENT = gql`
  fragment ConnectorFeatures on ConnectorDescriptor {
    name
    avatar
    description
    features {
      conversationFlowTesting
      e2eTesting
      deviceSetCapability
      deviceProviderTypes
      intentResolution
      intentConfidenceScore
      alternateIntents
      entityResolution
      entityConfidenceScore
      testCaseGeneration
      testCaseExport
      securityTesting
      audioInput
      sendAttachments
      supportedFileExtensions
      helloText
    }
  }
`

export const FULL_CONNECTORDESCRIPTOR_FRAGMENT = gql`
  fragment FullConnectorDescriptor on ConnectorDescriptor {
    name
    avatar
    description
    helperText
    capabilities {
      name
      label
      description
      type
      required
      advanced
      choices { key name description }
      sampleHeader
      sampleDescription
      sampleBody
    }
    features {
      conversationFlowTesting
      e2eTesting
      deviceSetCapability
      deviceProviderTypes
      intentResolution
      intentConfidenceScore
      alternateIntents
      entityResolution
      entityConfidenceScore
      testCaseGeneration
      testCaseExport
      securityTesting
      audioInput
      sendAttachments
      supportedFileExtensions
      helloText
    }
  }
`

export const FULL_LICENSE_FRAGMENT = gql`
  fragment FullLicense on License {
    edition
    email
    trial
    shared
    hostnames
    connectors
    maxexecutionduration
    maxtestsessionsperhour
    maxconvosbytestset
    maxutterancesbytestset
    maxagents
    maxchatbots
    maxtestsets
    detailedReporting
    deviceCloud
    bugExport
    gitIntegration
    performanceTesting
    performanceTestingOptions {
      maxTickTime
      maxTickMaxTime
      maxTickRepeatInitial
      maxTickRepeatPerTick
      maxParallelJobCount
      tickMaxErrorPercent
      shareContainer
      maxTickRepeatMax
     }
    securityTesting
    humanification
    gdprTesting
    multiTenant
    coach
    crawler
    testDataWizard
    paraphrasing
    monitoring
    notifications
    translation
    buildIntegration
    testsetImport
    testsetExport
    excel
    parallelexecution
    registerComponents
    components
    api
    userActivity
    isVersionValid
    isValid
    isOperable
    isHostnameValid
    validity
    operability
  }
`

export const LICENSE_QUERY = gql`
  query LicenseQuery {
    license {
      ...FullLicense
    }
  }
  ${FULL_LICENSE_FRAGMENT}
`

export const FULL_FEATURES_FRAGMENT = gql`
  fragment FullFeatureSwitches on FeatureSwitches {
    speechService
    repairTestProjectHistory
    sentryDsn
    publishMetrics
    qboxLoginBaseHref
    factCheckerEnabled
    misuseCheckerEnabled
    componentsBlacklist
    componentsWhitelist
  }
`
export const CHECKFEATURECONFIGURED_QUERY = gql`
  query CheckFeatureConfiguredQuery($feature: ClientFeature!) {
    checkfeatureconfigured(feature: $feature) {
      isLicensed
      isConfigured
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($user: UserCreateInput!) {
    createUser(user: $user) {
      ...ListUser
    }
  }
  ${LIST_USER_FRAGMENT}
`
export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $user: UserUpdateInput!) {
    updateUser(id: $id, user: $user) {
      ...ListUser
    }
  }
  ${LIST_USER_FRAGMENT}
`
export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`
export const SEND_USER_WELCOME_EMAIL = gql`
  mutation SendUserWelcomeEmail($id: ID!) {
    sendUserWelcomeEmail(id: $id)
  }
`
export const EXPIRE_PASSWORD = gql`
  mutation ExpirePassword($id: ID!) {
    expirePassword(id: $id)
  }
`

export const RefetchUserQueries = (userId) => [
  {
    query: USERS_QUERY,
  },
  ...(userId ? [
    {
      query: USER_QUERY,
      variables: { id: userId }
    }
  ] : [])
]

const LIST_APIKEY_FRAGMENT = gql`
  fragment ListApiKey on ApiKey {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    lastActivityAt
    name
    key
    validFrom
    validTo
    permissions
  }
`

export const APIKEY_QUERY = gql`
  query ApiKeyQuery($id: ID!) {
    apikey(id: $id) {
      ...ListApiKey
    }
  }
  ${LIST_APIKEY_FRAGMENT}
`

export const APIKEYS_QUERY = gql`
  query ApiKeysQuery {
    apikeys {
      ...ListApiKey
    }
  }
  ${LIST_APIKEY_FRAGMENT}
`

export const CREATE_APIKEY = gql`
  mutation CreateApiKey($apiKey: ApiKeyCreateInput!) {
    createApiKey(apiKey: $apiKey) {
      ...ListApiKey
    }
  }
  ${LIST_APIKEY_FRAGMENT}
`
export const UPDATE_APIKEY = gql`
  mutation UpdateApiKey($id: ID!, $apiKey: ApiKeyUpdateInput!) {
    updateApiKey(id: $id, apiKey: $apiKey) {
      ...ListApiKey
    }
  }
  ${LIST_APIKEY_FRAGMENT}
`
export const DELETE_APIKEY = gql`
  mutation DeleteApiKey($id: ID!) {
    deleteApiKey(id: $id)
  }
`

const LIST_DEVICEPROVIDER_FRAGMENT = gql`
  fragment ListDeviceProvider on DeviceProvider {
    id
    createdAt
    name
    type
    url
    username
    password
  }
`

export const DEVICEPROVIDER_QUERY = gql`
  query DeviceProviderQuery($id: ID!) {
    deviceprovider(id: $id) {
      ...ListDeviceProvider
    }
  }
  ${LIST_DEVICEPROVIDER_FRAGMENT}
`
export const DEVICEPROVIDERS_QUERY = gql`
  query DeviceProvidersQuery {
    deviceproviders {
      ...ListDeviceProvider
    }
  }
  ${LIST_DEVICEPROVIDER_FRAGMENT}
`
export const PING_DEVICEPROVIDER = gql`
  mutation PingDeviceProvider($id: ID!) {
    pingDeviceProvider(id: $id)
  }
`

export const CREATE_DEVICEPROVIDER = gql`
  mutation CreateDeviceProvider($deviceProvider: DeviceProviderCreateInput!) {
    createDeviceProvider(deviceProvider: $deviceProvider) {
      ...ListDeviceProvider
    }
  }
  ${LIST_DEVICEPROVIDER_FRAGMENT}
`
export const UPDATE_DEVICEPROVIDER = gql`
  mutation UpdateDeviceProvider($id: ID!, $deviceProvider: DeviceProviderUpdateInput!) {
    updateDeviceProvider(id: $id, deviceProvider: $deviceProvider) {
      ...ListDeviceProvider
    }
  }
  ${LIST_DEVICEPROVIDER_FRAGMENT}
`
export const DELETE_DEVICEPROVIDER = gql`
  mutation DeleteDeviceProvider($id: ID!) {
    deleteDeviceProvider(id: $id)
  }
`

const LIST_REGISTEREDCOMPONENT_FRAGMENT = gql`
  fragment ListRegisteredComponent on RegisteredComponent {
    id
    createdAt
    updatedAt
    name
    description
    type
    default
    src
    ref
    global
    args
    argsLabel
    sender
  }
`

export const REGISTEREDCOMPONENT_QUERY = gql`
  query RegisteredComponentQuery($id: ID!) {
    registeredcomponent(id: $id) {
      ...ListRegisteredComponent
    }
  }
  ${LIST_REGISTEREDCOMPONENT_FRAGMENT}
`

export const REGISTEREDCOMPONENTS_QUERY = gql`
  query RegisteredComponentsQuery {
    registeredcomponents {
      ...ListRegisteredComponent
    }
  }
  ${LIST_REGISTEREDCOMPONENT_FRAGMENT}
`

export const VALIDATEREGISTEREDCOMPONENT_QUERY = gql`
  query ValidateRegisteredComponentQuery(
    $registeredComponent: RegisteredComponentUpdateInput!
  ) {
    validateregisteredcomponent(registeredComponent: $registeredComponent)
  }
`

export const CREATE_REGISTEREDCOMPONENT = gql`
  mutation CreateRegisteredComponent($registeredComponent: RegisteredComponentCreateInput!) {
    createRegisteredComponent(registeredComponent: $registeredComponent) {
      ...ListRegisteredComponent
    }
  }
  ${LIST_REGISTEREDCOMPONENT_FRAGMENT}
`
export const UPDATE_REGISTEREDCOMPONENT = gql`
  mutation UpdateRegisteredComponent($id: ID!, $registeredComponent: RegisteredComponentUpdateInput!) {
    updateRegisteredComponent(id: $id, registeredComponent: $registeredComponent) {
      ...ListRegisteredComponent
    }
  }
  ${LIST_REGISTEREDCOMPONENT_FRAGMENT}
`
export const DELETE_REGISTEREDCOMPONENT = gql`
  mutation DeleteRegisteredComponent($id: ID!) {
    deleteRegisteredComponent(id: $id)
  }
`

const LIST_REGISTEREDAGGREGATOR_FRAGMENT = gql`
  fragment ListRegisteredAggregator on RegisteredAggregator {
    id
    createdAt
    updatedAt
    name
    label
    description
    global
    type
    function
    context
    jsonPathFilterExpression
    jsonPathFilterValue
    jsonPathExtractExpression
  }
`

export const REGISTEREDAGGREGATOR_QUERY = gql`
  query RegisteredAggregatorQuery($id: ID!) {
    registeredaggregator(id: $id) {
      ...ListRegisteredAggregator
    }
  }
  ${LIST_REGISTEREDAGGREGATOR_FRAGMENT}
`

export const REGISTEREDAGGREGATORS_QUERY = gql`
  query RegisteredAggregatorsQuery {
    registeredaggregators {
      ...ListRegisteredAggregator
    }
  }
  ${LIST_REGISTEREDAGGREGATOR_FRAGMENT}
`

export const CREATE_REGISTEREDAGGREGATOR = gql`
  mutation CreateRegisteredAggregator($aggregator: RegisteredAggregatorInput!) {
    createRegisteredAggregator(aggregator: $aggregator) {
      ...ListRegisteredAggregator
    }
  }
  ${LIST_REGISTEREDAGGREGATOR_FRAGMENT}
`
export const UPDATE_REGISTEREDAGGREGATOR = gql`
  mutation UpdateRegisteredAggregator($id: ID!, $aggregator: RegisteredAggregatorInput!) {
    updateRegisteredAggregator(id: $id, aggregator: $aggregator) {
      ...ListRegisteredAggregator
    }
  }
  ${LIST_REGISTEREDAGGREGATOR_FRAGMENT}
`
export const DELETE_REGISTEREDAGGREGATOR = gql`
  mutation DeleteRegisteredAggregator($id: ID!) {
    deleteRegisteredAggregator(id: $id)
  }
`

const LIST_SPEECHSYNTHESISPROFILE_FRAGMENT = gql`
  fragment ListSpeechSynthesisProfile on SpeechSynthesisProfile {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    engine
    language
    voice
    bspEndpoint
    bspApiKey
    bspSkipCache
    credentials
    config
  }
`

export const SPEECHSYNTHESISPROFILES_QUERY = gql`
  query SpeechSynthesisProfilesQuery {
    speechSynthesisProfiles {
      ...ListSpeechSynthesisProfile
    }
  }
  ${LIST_SPEECHSYNTHESISPROFILE_FRAGMENT}
`

export const SPEECHSYNTHESISPROFILE_QUERY = gql`
  query SpeechSynthesisProfileQuery($id: ID!) {
    speechSynthesisProfile(id: $id) {
      ...ListSpeechSynthesisProfile
    }
  }
  ${LIST_SPEECHSYNTHESISPROFILE_FRAGMENT}
`

export const CREATE_SPEECHSYNTHESISPROFILE = gql`
  mutation CreateSpeechSynthesisProfile($profile: SpeechSynthesisProfileInput!) {
    createSpeechSynthesisProfile(profile: $profile) {
      ...ListSpeechSynthesisProfile
    }
  }
  ${LIST_SPEECHSYNTHESISPROFILE_FRAGMENT}
`
export const CLONE_SPEECHSYNTHESISPROFILE = gql`
  mutation CloneSpeechSynthesisProfile($id: ID!) {
    cloneSpeechSynthesisProfile(id: $id) {
      ...ListSpeechSynthesisProfile
    }
  }
  ${LIST_SPEECHSYNTHESISPROFILE_FRAGMENT}
`
export const UPDATE_SPEECHSYNTHESISPROFILE = gql`
  mutation UpdateSpeechSynthesisProfile($id: ID!, $profile: SpeechSynthesisProfileInput!) {
    updateSpeechSynthesisProfile(id: $id, profile: $profile) {
      ...ListSpeechSynthesisProfile
    }
  }
  ${LIST_SPEECHSYNTHESISPROFILE_FRAGMENT}
`
export const DELETE_SPEECHSYNTHESISPROFILE = gql`
  mutation DeleteSpeechSynthesisProfile($id: ID!) {
    deleteSpeechSynthesisProfile(id: $id)
  }
`
export const TEST_SPEECHSYNTHESISPROFILE = gql`
  query SpeechSynthesisProfileTest($text: String!, $id: ID, $profile: SpeechSynthesisProfileInput) {
    speechSynthesisProfileTest(text: $text, id: $id, profile: $profile)
  }
`

const LIST_SPEECHRECOGNITIONPROFILE_FRAGMENT = gql`
  fragment ListSpeechRecognitionProfile on SpeechRecognitionProfile {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    engine
    language
    bspEndpoint
    bspApiKey
    bspSkipCache
    credentials
    config
  }
`

export const SPEECHRECOGNITIONPROFILES_QUERY = gql`
  query SpeechRecognitionProfilesQuery {
    speechRecognitionProfiles {
      ...ListSpeechRecognitionProfile
    }
  }
  ${LIST_SPEECHRECOGNITIONPROFILE_FRAGMENT}
`

export const SPEECHRECOGNITIONPROFILE_QUERY = gql`
  query SpeechRecognitionProfileQuery($id: ID!) {
    speechRecognitionProfile(id: $id) {
      ...ListSpeechRecognitionProfile
    }
  }
  ${LIST_SPEECHRECOGNITIONPROFILE_FRAGMENT}
`

export const CREATE_SPEECHRECOGNITIONPROFILE = gql`
  mutation CreateSpeechRecognitionProfile($profile: SpeechRecognitionProfileInput!) {
    createSpeechRecognitionProfile(profile: $profile) {
      ...ListSpeechRecognitionProfile
    }
  }
  ${LIST_SPEECHRECOGNITIONPROFILE_FRAGMENT}
`
export const CLONE_SPEECHRECOGNITIONPROFILE = gql`
  mutation CloneSpeechRecognitionProfile($id: ID!) {
    cloneSpeechRecognitionProfile(id: $id) {
      ...ListSpeechRecognitionProfile
    }
  }
  ${LIST_SPEECHRECOGNITIONPROFILE_FRAGMENT}
`
export const UPDATE_SPEECHRECOGNITIONPROFILE = gql`
  mutation UpdateSpeechRecognitionProfile($id: ID!, $profile: SpeechRecognitionProfileInput!) {
    updateSpeechRecognitionProfile(id: $id, profile: $profile) {
      ...ListSpeechRecognitionProfile
    }
  }
  ${LIST_SPEECHRECOGNITIONPROFILE_FRAGMENT}
`
export const DELETE_SPEECHRECOGNITIONPROFILE = gql`
  mutation DeleteSpeechRecognitionProfile($id: ID!) {
    deleteSpeechRecognitionProfile(id: $id)
  }
`
export const OPEN_SPEECHRECOGNITIONPROFILE_STREAM = gql`
  query SpeechRecognitionProfileStream($id: ID, $profile: SpeechRecognitionProfileInput) {
    speechRecognitionProfileStream(id: $id, profile: $profile) {
      wsUri
      statusUri
      endUri
    }
  }
`

const LIST_SPEECHNOISEPROFILE_FRAGMENT = gql`
  fragment ListSpeechNoiseProfile on SpeechNoiseProfile {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    bspEndpoint
    bspApiKey
    bspSkipCache
    steps { id position effect args }
  }
`

export const SPEECHNOISEPROFILES_QUERY = gql`
  query SpeechNoiseProfilesQuery {
    speechNoiseProfiles {
      ...ListSpeechNoiseProfile
    }
  }
  ${LIST_SPEECHNOISEPROFILE_FRAGMENT}
`

export const SPEECHNOISEPROFILE_QUERY = gql`
  query SpeechNoiseProfileQuery($id: ID!) {
    speechNoiseProfile(id: $id) {
      ...ListSpeechNoiseProfile
    }
  }
  ${LIST_SPEECHNOISEPROFILE_FRAGMENT}
`

export const CREATE_SPEECHNOISEPROFILE = gql`
  mutation CreateSpeechNoiseProfile($profile: SpeechNoiseProfileInput!) {
    createSpeechNoiseProfile(profile: $profile) {
      ...ListSpeechNoiseProfile
    }
  }
  ${LIST_SPEECHNOISEPROFILE_FRAGMENT}
`
export const CLONE_SPEECHNOISEPROFILE = gql`
  mutation CloneSpeechNoiseProfile($id: ID!) {
    cloneSpeechNoiseProfile(id: $id) {
      ...ListSpeechNoiseProfile
    }
  }
  ${LIST_SPEECHNOISEPROFILE_FRAGMENT}
`
export const UPDATE_SPEECHNOISEPROFILE = gql`
  mutation UpdateSpeechNoiseProfile($id: ID!, $profile: SpeechNoiseProfileInput!) {
    updateSpeechNoiseProfile(id: $id, profile: $profile) {
      ...ListSpeechNoiseProfile
    }
  }
  ${LIST_SPEECHNOISEPROFILE_FRAGMENT}
`
export const DELETE_SPEECHNOISEPROFILE = gql`
  mutation DeleteSpeechNoiseProfile($id: ID!) {
    deleteSpeechNoiseProfile(id: $id)
  }
`

const SYSTEMSETTINGS_FRAGMENT = gql`
  fragment FullSystemSettings on SystemSettings {
    id
    keepTestCaseSuccessScreenshotsDays
    keepTestCaseSuccessConversationDays
    keepTestCaseFailedScreenshotsDays
    keepTestCaseFailedConversationDays
    keepTrainerSessionDetailsDays
    graphQLDebugging
    quillbotApiKey
    quillbotApiSettings
    openaiParaphraserApiKey
    openaiParaphraserApiSettings
    openaiParaphraserLanguage
    openaiParaphraserCommands
    openaiParaphraserCommandsAutoApply
    googleTranslateCredentials
    smtpServer
    smtpPort
    smtpUser
    smtpPassword
    smtpSecure
    smtpFrom
    passwordPolicyMinimumLength
    passwordPolicyMinNumbers
    passwordPolicyMinSpecial
    passwordPolicyMinLowerCase
    passwordPolicyMinUpperCase
    passwordPolicyExpiryDays
    passwordPolicyLastX
    checkNamespacePermissions
    skipUserActivityLog
    keepUserActivityLogDays
    llmModelId
    llmGptApiKey
    llmLLamaAwsRegion
    llmAwsAccessKeyId
    llmAwsSecretAccessKey
  }
`

export const SYSTEMSETTINGS_QUERY = gql`
  query SystemSettingsQuery {
    systemsettings {
      ...FullSystemSettings
    }
  }
  ${SYSTEMSETTINGS_FRAGMENT}
`

export const UPDATE_SYSTEMSETTINGS = gql`
  mutation UpdateSystemSettings($systemSettings: SystemSettingsUpdateInput!) {
    updateSystemSettings(systemSettings: $systemSettings) {
      ...FullSystemSettings
    }
  }
  ${SYSTEMSETTINGS_FRAGMENT}
`

export const SEND_TEST_EMAIL = gql`
  mutation SendTestEmail($receiver: String!, $systemSettings: SystemSettingsUpdateInput, $text: String, $subject: String) {
    sendTestEmail(receiver: $receiver, systemSettings: $systemSettings, text: $text, subject: $subject)
  }
`

export const EXIT_SERVER = gql`
  mutation ExitServer {
    exitServer
  }
`

export const FLUSH_CACHES = gql`
  mutation FlushCaches {
    flushCaches
  }
`

export const TAGS_QUERY = gql`
  query TagsQuery {
    tags {
      id
      name
    }
  }
`

export const NAMESPACES_QUERY = gql`
  query NamespacesQuery {
    namespaces {
      id
      namespace
      canRead
      canWrite
    }
  }
`

export const NAMESPACE_QUERY = gql`
  query NamespaceQuery($id: ID!) {
    namespace(id: $id) {
      id
      namespace
      canRead
      canWrite
      entries { entity count }
    }
  }
`

export const DELETE_NAMESPACE = gql`
  mutation DeleteNamespace($id: ID!) {
    deleteNamespace(id: $id)
  }
`

export const IMPORT_PRISMA = gql`
  mutation ImportPrisma($prismaFile: String!, $allowedModel: String) {
    importPrisma(prismaFile: $prismaFile, allowedModel: $allowedModel)
  }
`

export const IMPORT_PRISMA_FROM_URL = gql`
  mutation ImportPrismaFromUrl($prismaUrl: String!) {
    importPrismaFromUrl(prismaUrl: $prismaUrl)
  }
`

export const FILESYSTEM_QUERY = gql`
  query FileBrowserGetContentQuery($path: [String!]!) {
    fileBrowserGetContent(path: $path) {
      path
      content {
        type
        name
        mimeType
        createdAt
        updatedAt
        size
      }
    }
  }
`

export const FILESYSTEM_HASCONTENT_QUERY = gql`
  query FileBrowserHasContentQuery($path: [String!]!, $names: [String!]!) {
    fileBrowserHasContent(path: $path, names: $names)
  }
`

export const FILESYSTEM_UPLOAD_CONTENT = gql`
  mutation FileBrowserUploadContent($path: [String!]!, $filename: String!, $filecontent: String!, $unzip: Boolean) {
    fileBrowserUploadContent(path: $path, filename: $filename, filecontent: $filecontent, unzip: $unzip)
  }
`

export const FILESYSTEM_DELETE_CONTENT = gql`
  mutation FileBrowserDeleteContent($path: [String!]!, $filename: String!) {
    fileBrowserDeleteContent(path: $path, filename: $filename)
  }
`

export const FILESYSTEM_MOVE_CONTENT = gql`
  mutation FileBrowserMoveContent($path: [String!]!, $names: [String!]!, $destinationPath: [String!]!) {
    fileBrowserMoveContent(path: $path, names: $names, destinationPath: $destinationPath)
  }
`

export const FILESYSTEM_COPY_CONTENT = gql`
  mutation FileBrowserCopyContent($path: [String!]!, $names: [String!]!, $destinationPath: [String!]!) {
    fileBrowserCopyContent(path: $path, names: $names, destinationPath: $destinationPath)
  }
`

export const FILESYSTEM_DELETE_BULK = gql`
  mutation FileBrowserDeleteBulk($path: [String!]!, $names: [String!]!, $force: Boolean) {
    fileBrowserDeleteBulk(path: $path, names: $names, force: $force)
  }
`

export const FILESYSTEM_RENAME_CONTENT = gql`
  mutation fileBrowserRenameContent($path: [String!]!, $oldname: String!, $newname: String!) {
    fileBrowserRenameContent(path: $path, oldname: $oldname, newname: $newname)
  }
`

export const FILESYSTEM_CREATE_FOLDER = gql`
  mutation FileBrowserCreateFolder($path: [String!]!, $dirname: String!) {
    fileBrowserCreateFolder(path: $path, dirname: $dirname)
  }
`

export const FILESYSTEM_DELETE_FOLDER = gql`
  mutation FileBrowserDeleteFolder($path: [String!]!, $dirname: String!, $force: Boolean) {
    fileBrowserDeleteFolder(path: $path, dirname: $dirname, force: $force)
  }
`

export const PACKAGE_VERSIONS_QUERY = gql`
  query PackageVersionsQuery {
    packageVersions {
      name
      version
    }
  }
`

export const CLIENT_CONSUMPTION_QUERY = gql`
  query ClientConsumptionQuery {
    clientConsumption {
      pricingPlan
      consumptionLimit
      pricingPlanLimit
      currentBillCycleStartDate
      monitoringHealthcheck
      regression
      nlp
      nlpInsights
      performance
      performanceLite
      e2eWebMobile
      e2eVoiceIvr
      aiDataGeneration
      security
      gdpr
      totalConsumption
    }
  }
`

export const CLIENT_CONSUMPTION_LOG_QUERY = gql`
  query ClientConsumptionLogQuery($startDate: String, $endDate: String, $aggregation: String, $categories: String) {
    clientConsumptionLog(startDate: $startDate, endDate: $endDate, aggregation: $aggregation, categories: $categories) {
      aggregation
      startDate
      endDate
      centerDate
      monitoringHealthcheck
      regression
      nlp
      nlpInsights
      performance
      performanceLite
      e2eWebMobile
      e2eVoiceIvr
      aiDataGeneration
      totalConsumption
    }
  }
`

export const CONSUMPTION_SETTINGS_QUERY = gql`
  query ConsumptionSettings {
    consumptionSettings {
      consumptionLimit,
      consumptionNotification,
      notificationReceiversEmail
    }
  }
`

export const UPDATE_CONSUMPTION_SETTINGS = gql`
  mutation UpdateConsumptionSettings($consumptionSettings: ConsumptionSettingsUpdateInput!) {
    updateConsumptionSettings(consumptionSettings: $consumptionSettings) {
      consumptionLimit,
      consumptionNotification,
      notificationReceiversEmail
    }
  }
`

export const FULL_DEVICESET_FRAGMENT = gql`
  fragment FullDeviceSet on DeviceSet {
    id
    createdAt
    name
    description
    tags
    provider {
      id
      name
      type
    }
    devices {
      id
      name
      type
      capabilities
    }
  }
`

export const DeleteDeviceSetsListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^devicesets/)
}

export const DEVICESET_QUERY = gql`
  query DeviceSetQuery($id: ID!) {
    deviceset(id: $id) {
      ...FullDeviceSet
    }
  }
  ${FULL_DEVICESET_FRAGMENT}
`

export const LIST_DEVICESET_FRAGMENT = gql`
  fragment ListDeviceSet on DeviceSet {
    id
    createdAt
    name
    description
    tags
    provider {
      id
      name
      type
    }
    devices {
      id
      name
      type
    }
  }
`

export const DEVICESETS_QUERY = gql`
  query DeviceSetsQuery($where: DeviceSetWhereInput) {
    devicesets(where: $where) {
      ...ListDeviceSet
    }
  }
  ${LIST_DEVICESET_FRAGMENT}
`

export const DEVICESETS_DROPDOWN_QUERY = gql`
  query DeviceSetsQuery($where: DeviceSetWhereInput) {
    devicesets(where: $where) {
      id
      name
    }
  }
`

export const DEVICESET_DROPDOWN_QUERY = gql`
  query DeviceSetDropdownQuery($provider: ID!) {
    availabledevices(provider: $provider) {
      name
      value
    }
  }
`

export const CREATE_DEVICESET = gql`
  mutation CreateDeviceSet($deviceSet: DeviceSetCreateInput!) {
    createDeviceSet(deviceSet: $deviceSet) {
      ...FullDeviceSet
    }
  }
  ${FULL_DEVICESET_FRAGMENT}
`

export const UPDATE_DEVICESET = gql`
  mutation UpdateDeviceSet($id: ID!, $deviceSet: DeviceSetUpdateInput!) {
    updateDeviceSet(id: $id, deviceSet: $deviceSet) {
      ...FullDeviceSet
    }
  }
  ${FULL_DEVICESET_FRAGMENT}
`

export const DELETE_DEVICESET = gql`
  mutation DeleteDeviceSet($id: ID!) {
    deleteDeviceSet(id: $id)
  }
`

export const AGENTS_QUERY = gql`
  query AgentsQuery {
    agents {
      id
      name
      description
      tags
      heartbeat
      heartbeatInfo
      connectionStatus
      debug
    }
  }
`

export const AGENTS_DROPDOWN_QUERY = gql`
  query AgentsQuery {
    agents(where: { name_not: "Default Agent" }) {
      id
      name
      heartbeat
      heartbeatInfo
      connectionStatus
    }
  }
`

const FULL_AGENT_FRAGMENT = gql`
  fragment FullAgent on Agent {
    id
    createdAt
    updatedAt
    name
    description
    tags
    heartbeat
    heartbeatInfo
    connectionStatus
    debug
    capabilities {
      id
      createdAt
      updatedAt
      name
      type
      stringValue
      intValue
      booleanValue
      jsonValue
    }
    envs {
      id
      createdAt
      updatedAt
      name
      stringValue
    }
  }
`

export const AGENT_QUERY = gql`
  query AgentQuery($id: ID!) {
    agent(id: $id) {
      ...FullAgent
    }
  }
  ${FULL_AGENT_FRAGMENT}
`

export const CREATE_AGENT = gql`
  mutation CreateAgent($agent: AgentCreateInput!) {
    createAgent(agent: $agent) {
      ...FullAgent
    }
  }
  ${FULL_AGENT_FRAGMENT}
`

export const UPDATE_AGENT = gql`
  mutation UpdateAgent($id: ID!, $agent: AgentUpdateInput!) {
    updateAgent(id: $id, agent: $agent) {
      ...FullAgent
    }
  }
  ${FULL_AGENT_FRAGMENT}
`

export const DELETE_AGENT = gql`
  mutation DeleteAgent($id: ID!) {
    deleteAgent(id: $id)
  }
`

export const CAPABILITYSETS_QUERY = gql`
  query CapabilitySetsQuery {
    capabilitySets {
      id
      name
      capabilities {
        id
        name
      }
    }
  }
`

export const CAPABILITYSETS_DROPDOWN_QUERY = gql`
  query CapabilitySetsQuery {
    capabilitySets {
      id
      name
      chatbots {
        id
        name
      }
    }
  }
`

const FULL_CAPABILITYSET_FRAGMENT = gql`
  fragment FullCapabilitySet on CapabilitySet {
    id
    createdAt
    updatedAt
    name
    capabilities {
      id
      createdAt
      updatedAt
      name
      type
      stringValue
      intValue
      booleanValue
      jsonValue
    }
    chatbots {
      id
      name
    }
  }
`

export const CAPABILITYSET_QUERY = gql`
  query CapabilitySetQuery($id: ID!) {
    capabilitySet(id: $id) {
      ...FullCapabilitySet
    }
  }
  ${FULL_CAPABILITYSET_FRAGMENT}
`

export const CREATE_CAPABILITYSET = gql`
  mutation CreateCapabilitySet($capabilitySet: CapabilitySetCreateInput!) {
    createCapabilitySet(capabilitySet: $capabilitySet) {
      ...FullCapabilitySet
    }
  }
  ${FULL_CAPABILITYSET_FRAGMENT}
`

export const UPDATE_CAPABILITYSET = gql`
  mutation UpdateCapabilitySet($id: ID!, $capabilitySet: CapabilitySetUpdateInput!) {
    updateCapabilitySet(id: $id, capabilitySet: $capabilitySet) {
      ...FullCapabilitySet
    }
  }
  ${FULL_CAPABILITYSET_FRAGMENT}
`

export const DELETE_CAPABILITYSET = gql`
  mutation DeleteCapabilitySet($id: ID!) {
    deleteCapabilitySet(id: $id)
  }
`

export const PING_AGENT = gql`
  mutation PingAgent($id: ID!) {
    pingAgent(id: $id)
  }
`

export const SYSTEMSETTINGSOPTIONS_QUERY = gql`
  query SystemSettingsOptionsQuery {
    systemsettingoptions {
      translationFrom { name value }
      translationTo  { name value }
    }
  }
`

export const EXECUTE_PARAPHRASER_POST_PROCESSING_QUERY = gql`
  query ExecuteParaphraserPostProcessing($utterances: [String!]!, $openaiCommands: [String!]!) {
    executeparaphraserpostprocessing(utterances: $utterances, openaiCommands: $openaiCommands)
  }
`
