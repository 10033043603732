import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { compose, graphql } from 'react-apollo'

// exernal modules
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'

// shared
import { hasPermission, canReadNamespace, canWriteNamespace } from 'botium-box-shared/security/permissions'

// core components
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable'
import DropdownButton from 'components/Button/DropdownButton'
import { isDarkmode } from 'components/Darkmode/helper'
import DateFormat from 'components/Info/DateFormat'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'

// everything else
import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { getConnector } from 'actions/settings'
import { NewResultStats } from '../TestSessions/helper'
import { TestSessionType, NewResultIcon } from 'views/TestSessions/helper'
import { projectTypeFlagsToUrl } from './helper'

import {
  START_FACTCHECKERPROJECT,
  START_FACTCHECKERPROJECTS,
  FACTCHECKERPROJECTS_PAGINATED_QUERY,
  FACTCHECKERPROJECTS_COUNT_QUERY,
  DeleteFactCheckerListsFromCache,
  START_MISUSECHECKERPROJECT,
  START_MISUSECHECKERPROJECTS,
  MISUSECHECKERPROJECTS_PAGINATED_QUERY,
  MISUSECHECKERPROJECTS_COUNT_QUERY,
  DeleteMisuseCheckerListsFromCache
} from '../LLMprojects/gql'

import {
  RefetchTestSessionQueries,
  DeleteTestSessionListsFromCache
} from '../TestSessions/gql'

import {
  DeleteTestProjectsListsFromCache,
  RefetchTestProjectQueriesOnNewTestSession,
  START_TESTPROJECT,
  START_TESTPROJECTS,
  TESTPROJECTS_COUNT_PAGINATED_QUERY,
  TESTPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY,
  ANYPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY
} from './gql'

class GeneralTestProjectsEmbeddedTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedItems: [],
      newTestSessionCount: 0
    }
  }

  handleSelection(id) {
    const { selectedItems } = this.state
    const index = selectedItems.indexOf(id)
    if (index > -1) {
      selectedItems.splice(index, 1)
    } else {
      selectedItems.push(id)
    }
    this.setState({ selectedItems })
  }

  render() {
    const { classes, name, user, history, namespace,
      mutateStartTestProject, mutateStartTestProjects, projectFlags, variables,
      mutateStartFactCheckerProject, mutateStartFactCheckerProjects,
      mutateStartMisuseCheckerProject, mutateStartMisuseCheckerProjects,
      projectType,
      ...rest } = this.props

    const PROJECT_TYPE_DESCRIPTORS = {
      base: {
        type: 'base',
        gqlQuery: TESTPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY,
        gqlCountQuery: TESTPROJECTS_COUNT_PAGINATED_QUERY,
        // deleteListsFromCache: DeleteFactCheckerListsFromCache,
        startLabel: 'Start Test Session',
        startMoreLabel: 'Start Test Session',
        _gqlVariables: (filterString) => {
          filterString = filterString && filterString.replace('_', '\\_')
          // botiumFormat means that the variable (or at least the thins below botiumFormat) are not well formatted yet
          // Those are variables need extra attention, they have to set differently for the queries.
          const where = (variables && !variables.botiumFormat) ? { AND: [ {...variables } ] } : { }
          if (variables && variables.botiumFormat) {
            if (variables.botiumFormat.testSetId) {
              where.testSets_some = { id: variables.botiumFormat.testSetId }
            }
            if (variables.botiumFormat.chatbotId) {
              where.chatbot = { id: variables.botiumFormat.chatbotId }
            }
          }
          if (filterString) {
            if (!where.AND) {
              where.AND = []
            }
            where.AND.push({ OR: [ {name_contains: filterString}, { chatbot: {name_contains: filterString} }, { testSets_some: {name_contains: filterString} } ] })
          }
          if (namespace?.selected?.name) {
            where.namespace = namespace?.selected?.name
          }
          return { where }
        },
        _projectLink: tp => {
          const prefix = projectTypeFlagsToUrl(projectFlags || {}) ||
            projectTypeFlagsToUrl(tp.lastTestSession || {}) ||
            projectTypeFlagsToUrl(tp) ||
            '/regression'

          return `${prefix}/projects/view/${tp.id}`
        },
        _resultLink: (ts, tp) => {
          if (ts.type === 'nlp') {
            return `/nlp/projects/view/${tp.id}/results/${ts.id}`
          } else if (ts.type === 'security') {
            return `/security/projects/view/${tp.id}/results/${ts.id}`
          } else if (ts.type === 'gdpr') {
            return `/gdpr/projects/view/${tp.id}/results/${ts.id}`
          } else if (ts.type === 'factchecker') {
            return `/factcheck/projects/view/${tp.factCheckerSession.project.id}/results/${ts.id}`
          } else if (ts.type === 'misusechecker') {
            return `/misusecheck/projects/view/${tp.misuseCheckerSession.project.id}/results/${ts.id}`
          } else if (ts.type === 'performance' && ts.performanceTestSession) {
            return `/performance/projects/view/${tp.id}/results/${ts.performanceTestSession.id}`
          } else if (ts.type === 'e2e') {
            return `/e2e/projects/view/${tp.id}/results/${ts.id}/dashboard`
          } else if (ts.type === 'monitoring') {
            return `/scheduledtests/projects/view/${tp.id}/results/${ts.id}/dashboard`
          }
          return `/regression/projects/view/${tp.id}/results/${ts.id}/dashboard`
        },
        _countValue: data => data.testprojectsCount,
        _responseToList: data => {
          return data && data.testprojectswithlasttestsession ? data.testprojectswithlasttestsession : []
        },
        _mutateMore: (variables) => mutateStartTestProjects({
          variables: {
            testProjectIds: variables.ids,
            debug: variables.debug
          }
        }),
        _mutate: (variables) => mutateStartTestProject({variables}),
        _testSession: t => t?.lastTestSession,
        //EXTRAS
        _showBulkActions: () => {
          return projectFlags && !!(projectFlags.nlpAnalytics || projectFlags.securityCheck || projectFlags.e2eTesting || projectFlags.regressionTesting || projectFlags.monitoring)
        },
        _openBaseMenu: (t) => {
          return t.lastTestSession && !['e2e', 'regression', 'performance'].some(type => t.lastTestSession.type === type) && {
            id: 'openbase',
            icon: 'magnifying-glass',
            name: 'Open Latest Base Test Session',
            onClick: () => history.push(`/regression/projects/view/${t.id}/results/${t.lastTestSession.id}`)
          }
        },
        _assignSessionTypes: (tp) => {
            if (tp.lastTestSession) {
              tp.lastTestSession.type =
                tp.lastTestSession.nlpAnalytics ? 'nlp' :
                  tp.lastTestSession.securityCheck ? 'security' :
                    tp.lastTestSession.performanceTesting ? 'performance' :
                      tp.lastTestSession.gdprTesting ? 'gdpr' : null
              if (!tp.lastTestSession.type) {
                if (tp.regressionTesting) {
                  tp.lastTestSession.type = 'regression'
                } else if (tp.e2eTesting) {
                  tp.lastTestSession.type = 'e2e'
                }
              }
              if (!tp.lastTestSession.type) {
                console.error('Cannot determine session type', tp)
                tp.lastTestSession.type = 'regression'
              }
            }
        },
      },
      factchecker: {
        type: 'factchecker',
        gqlQuery: FACTCHECKERPROJECTS_PAGINATED_QUERY,
        gqlCountQuery: FACTCHECKERPROJECTS_COUNT_QUERY,
        startLabel: 'Start FactCheck Test Session',
        startMoreLabel: 'Start FactCheck Test Sessions',
        _gqlVariables: (filterString) => {
          filterString = filterString && filterString.replace('_', '\\_')
          const where = (variables && !variables.botiumFormat) ? { ...variables } : { }
          if (variables && variables.botiumFormat) {
            if (variables.botiumFormat.testSetId) {
              where.testSet = { id: variables.botiumFormat.testSetId }
            }
            if (variables.botiumFormat.chatbotId) {
              where.chatbotId = variables.botiumFormat.chatbotId
            }
          }
          if (filterString) {
            where.name = filterString
          }
          if (namespace?.selected?.name) {
            where.namespace = namespace?.selected?.name
          }
          return { where }
        },
        _projectLink: tp => `/factcheck/projects/view/${tp.id}`,
        _resultLink: (ts, tp) => `/factcheck/projects/view/${ts.factCheckerSession.project.id}/results/${ts.id}`,
        _countValue: data => data.factcheckerprojectsCount,
        _responseToList: data => data && data.factcheckerprojectswithlastfactcheckersession ? data.factcheckerprojectswithlastfactcheckersession : [],
        _mutateMore: (variables) => mutateStartFactCheckerProjects({variables: {factCheckerProjectIds: variables.ids, debug: variables.debug }}),
        _mutate: (variables) => mutateStartFactCheckerProject({variables}),
        _testSession: testProject => testProject?.lastFactCheckerSession?.testSession
      },
      misusechecker: {
        type: 'misusechecker',
        gqlQuery: MISUSECHECKERPROJECTS_PAGINATED_QUERY,
        gqlCountQuery: MISUSECHECKERPROJECTS_COUNT_QUERY,
        startLabel: 'Start LLM Misuse Checker Test Session',
        startMoreLabel: 'Start LLM Misuse Checker Test Sessions',
        _gqlVariables: (filterString) => {
          filterString = filterString && filterString.replace('_', '\\_')
          const where = (variables && !variables.botiumFormat) ? { ...variables }: { }
          if (variables && variables.botiumFormat) {
            if (variables.botiumFormat.testSetId) {
              console.error('Filtering by TestSetId not supported for misusechecker')
            }
            if (variables.botiumFormat.chatbotId) {
              where.chatbotId = variables.botiumFormat.chatbotId
            }
          }

          if (filterString) {
            where.name = filterString
          }
          if (namespace?.selected?.name) {
            where.namespace = namespace?.selected?.name
          }

          return { where }
        },
        _projectLink: tp => `/misusecheck/projects/view/${tp.id}`,
        _resultLink: (ts, tp) => `/misusecheck/projects/view/${ts.misuseCheckerSession.project.id}/results/${ts.id}`,
        _countValue: data => data.misusecheckerprojectsCount,
        _responseToList: data => data && data.misusecheckerprojectswithlastmisusecheckersession ? data.misusecheckerprojectswithlastmisusecheckersession : [],
        _mutateMore: (variables) => mutateStartMisuseCheckerProjects({variables: {misuseCheckerProjectIds: variables.ids, debug: variables.debug}}),
        _mutate: (variables) => mutateStartMisuseCheckerProject({variables}),
        _testSession: testProject => testProject?.lastFactCheckerSession?.testSession
      },
      all: {
        type: 'all',
        gqlQuery: ANYPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY,
        _gqlVariables: (filterString) => {
          return {
            where: {
              testProject: PROJECT_TYPE_DESCRIPTORS.base._gqlVariables(filterString).where,
              factCheckerProject: PROJECT_TYPE_DESCRIPTORS.factchecker._gqlVariables(filterString).where,
              misuseCheckerProject: !(variables && variables.botiumFormat && variables.botiumFormat.testSetId) ? PROJECT_TYPE_DESCRIPTORS.misusechecker._gqlVariables(filterString).where : null
            }
          }
        },
        _responseToList: data => data && data.allprojects ? data.allprojects : [],
        _showBulkActions: () => false,
        // just all:
        _projectAndDescriptor: record => {
          if ( record.testProject ) {
            return { project: record.testProject, descriptor: PROJECT_TYPE_DESCRIPTORS.base }
          }
          if ( record.factCheckerProject ) {
            return { project: record.factCheckerProject, descriptor: PROJECT_TYPE_DESCRIPTORS.factchecker }
          }
          if ( record.misuseCheckerProject ) {
            return { project: record.misuseCheckerProject, descriptor: PROJECT_TYPE_DESCRIPTORS.misusechecker }
          }
        }
      }
    }

    const DESCRIPTOR = PROJECT_TYPE_DESCRIPTORS[projectType]
    if (!DESCRIPTOR) {
      return <div>Unknown project type {projectType}</div>
    }

    return <ServerSidePagingTable
      className={classes.projectTable}
      key={`${name}_${namespace?.selected?.name}_${isDarkmode() ? 'dark' : 'light'}_${this.state.newTestSessionCount}`}
      name={`${name}_${this.props.location.pathname.split('/').filter(p => p)[0]}`}
      gqlQuery={{
        query: DESCRIPTOR.gqlQuery,
        notifyOnNetworkStatusChange: true
      }}
      addNoPaddingTableActionsToolbar={this.props.addNoPaddingTableActionsToolbar}
      rowClassName={(row) => 'projectTable'}
      gqlCountQuery={{
        query: DESCRIPTOR.gqlCountQuery,
        countValue: DESCRIPTOR._countValue
      }}
      gqlVariables={(filterString) => DESCRIPTOR._gqlVariables(filterString)}
      tableHeaderColor="primary"
      noDataAllComponent="Get started creating test will be displayed on this page."
      tableHead={[
        { name: 'Project Name', width: 'small', orderByField: 'name', orderByDefault: 'updatedAt' },
        { name: 'Actions', right: true },
        { name: 'Latest Test', orderByField: 'name', orderByDefault: 'updatedAt',},
        ' ',
        ' ',
      ]}
      mapSelectIds={(!DESCRIPTOR._showBulkActions || DESCRIPTOR._showBulkActions()) ? (data => DESCRIPTOR._responseToList(data).map(t => t.id)) : undefined}
      onSelectionChange={selectedItems => this.setState({ selectedItems })}
      customActionsRight={
        (!DESCRIPTOR._showBulkActions || DESCRIPTOR._showBulkActions()) && <DropdownButton
          className={classes.dropdownButtonPosition}
          customSelectType
          disabled={this.state.selectedItems.length === 0}
          data-unique="btnProjectsBulkAction"
          items={[
            {
              id: 'start',
              icon: 'play',
              name: DESCRIPTOR.startMoreLabel,
              dataUnique: 'btnProjectsBulkActionStart',
              onClick: async () => {
                await DESCRIPTOR._mutateMore({ids: this.state.selectedItems, debug: false})
                this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
              }
            },
            {
              id: 'start_debug',
              icon: 'bug',
              name: `${DESCRIPTOR.startMoreLabel} (Extended Logging)`,
              dataUnique: 'btnProjectsBulkActionStartDebug',
              onClick: async () => {
                await DESCRIPTOR._mutateMore({ids: this.state.selectedItems, debug: true})
                this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
              }
            },
          ]}
        >
          <ShowIcon icon="redo" /> Bulk Action
        </DropdownButton>}

      tableData={data => DESCRIPTOR._responseToList(data).map((t, tIndex) => {
        const projectAndDescriptor = DESCRIPTOR._projectAndDescriptor?.(t)
        const LINE_DESCRIPTOR = projectAndDescriptor?.descriptor || DESCRIPTOR
        t = projectAndDescriptor?.project || t
        LINE_DESCRIPTOR._assignSessionTypes && LINE_DESCRIPTOR._assignSessionTypes(t)
        const ts = LINE_DESCRIPTOR._testSession(t)
        if (LINE_DESCRIPTOR.type === 'all') {
          console.err(`All descriptor is not able to render line! descriptor type: ${DESCRIPTOR.type}, project: ${JSON.stringify(t)}`)
        }
        return [
          {
            value: <>
              <div><NavLink className={classes.projectLink} to={`${LINE_DESCRIPTOR._projectLink(t)}/dashboard`}>{t.name}</NavLink></div>
              {t.namespace ? <NavLink to={`/namespaces/${t.namespace}`}><div className={classes.namespace}>{t.namespace}</div></NavLink> : <div className={classes.namespaceempty}></div>}
            </>
          },
          () => <DropdownButton
            dots
            aria-label="Actions"
            tooltipTitle="Actions"
            items={[
              {
                id: 'open',
                icon: 'infinity',
                name: 'Open',
                onClick: () => history.push(`${LINE_DESCRIPTOR._projectLink(t)}/dashboard`)
              },
              LINE_DESCRIPTOR._openBaseMenu && LINE_DESCRIPTOR._openBaseMenu(t),
              canReadNamespace(user, user.namespacePermissions, t.namespace) && {
                id: 'settings',
                icon: 'cog',
                name: 'Configuration',
                onClick: () => history.push(`${LINE_DESCRIPTOR._projectLink(t)}/settings`)
              },
              ...(hasPermission(user, 'TESTSESSIONS_CREATE') && canWriteNamespace(user, user.namespacePermissions, t.namespace) ? [
                {
                  id: 'start',
                  icon: 'play',
                  name: LINE_DESCRIPTOR.startLabel,
                  disabled: t.lastIndexSession && t.lastIndexSession.status === 'FAILED',
                  onClick: () => {
                    LINE_DESCRIPTOR._mutate({ id: t.id, debug: false })
                    this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
                  }
                },
                {
                  id: 'start_debug',
                  icon: 'bug',
                  name: `${LINE_DESCRIPTOR.startLabel} (Extended Logging)`,
                  disabled: t.lastIndexSession && t.lastIndexSession.status === 'FAILED',
                  onClick: () => {
                    LINE_DESCRIPTOR._mutate({ id: t.id, debug: true })
                    this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
                  }
                }
              ] : [])
            ].filter(t => t)}>
          </DropdownButton>,
          () => ts && <><div className={classes.resultListTestType}><TestSessionType ts={ts} /></div> <div className={classes.newResultIcon}><NewResultIcon ts={ts} /></div></>,
          () => ts && <><Text regular smLineHeight>Latest Test Run</Text><NavLink className={classes.projectLink} key={`${tIndex}`} to={LINE_DESCRIPTOR._resultLink(ts, t)} data-unique={`btnProjectMoveto_${tIndex}_${projectType}_${ts.type}`}><DateFormat>{ts.updatedAt}</DateFormat></NavLink></>,
          ts ? {
            value: <div><NewResultStats ts={ts} type={projectType} /></div>
          } : null,
        ]
      })}
      {...rest}
    />
  }
}

export default compose(
  withRouter,
  withStyles(testsessionsStyle),
  connect(
    state => ({
      user: state.token.user,
      license: state.settings.license,
      namespace: state.namespace
    }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage }
  ),
  graphql(START_TESTPROJECT, {
    props: ({ mutate }) => ({
      mutateStartTestProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('Test session started ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startTestProject.testProject.id),
        ...RefetchTestSessionQueries(data.startTestProject.id),
        ...(props.refetchQueriesForTestSession ? props.refetchQueriesForTestSession(data.startTestProject.id) : [])
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(START_TESTPROJECTS, {
    props: ({ mutate }) => ({
      mutateStartTestProjects: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('Test Sessions started ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Starting Test Sessions failed', error)
      },
      update: DeleteTestProjectsListsFromCache
    })
  }),
  graphql(START_FACTCHECKERPROJECT, {
    props: ({ mutate }) => ({
      mutateStartFactCheckerProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startFactCheckerProject.id
        props.setAlertSuccessMessage('FactCheck Test session started ...')
        props.history.push('/factcheck/projects/view/' + data.startFactCheckerProject.factCheckerSession.project.id + '/results/' + testSessionId)
      },
      onError: (error) => {
        props.setAlertErrorMessage('FactCheck Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestSessionQueries(data.startFactCheckerProject.id),
        ...(props.refetchQueriesForTestSession ? props.refetchQueriesForTestSession(data.startFactCheckerProject.id) : [])
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(START_FACTCHECKERPROJECTS, {
    props: ({ mutate }) => ({
      mutateStartFactCheckerProjects: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('FactCheck Sessions started ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Starting FactCheck Sessions failed', error)
      },
      update: DeleteFactCheckerListsFromCache
    })
  }),
  graphql(START_MISUSECHECKERPROJECT, {
    props: ({ mutate }) => ({
      mutateStartMisuseCheckerProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startMisuseCheckerProject.id
        props.setAlertSuccessMessage('LLM Misuse Checker Test session started ...')
        props.history.push('/misusecheck/projects/view/' + data.startMisuseCheckerProject.misuseCheckerSession.project.id + '/results/' + testSessionId)
      },
      onError: (error) => {
        props.setAlertErrorMessage('LLM Misuse Checker Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestSessionQueries(data.startMisuseCheckerProject.id),
        ...(props.refetchQueriesForTestSession ? props.refetchQueriesForTestSession(data.startMisuseCheckerProject.id) : [])
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(START_MISUSECHECKERPROJECTS, {
    props: ({ mutate }) => ({
      mutateStartMisuseCheckerProjects: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('LLM Misuse Checker Sessions started ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Starting LLM Misuse Checker Sessions failed', error)
      },
      update: DeleteMisuseCheckerListsFromCache
    })
  }),
)(GeneralTestProjectsEmbeddedTable)
