
import { createMuiTheme } from '@material-ui/core/styles'
import _ from 'lodash'

const primaryColor1 = '#2B3E53' // text
/*const primaryColor2 = '#979fa8' // text
const primaryColor3 = '#abb2b9'
const primaryColor4 = '#d5d8dd'
const primaryColor5 = '#e9ebed'
const primaryColor6 = '#2B3E5333' // border defaul color - by Gurbi*/
const primaryColor7 = '#f4f5f6'


const primaryColor9 = '#1a2738' // darkest
const primaryColor10 = '#556475' // text hover and border

const primaryColorPercent2 = '#2B3E5305' // primary color 3% Gurbi
const primaryColorPercent3 = '#2B3E5308' // primary color 3% Gurbi
const primaryColorPercent5 = '#2B3E530D' // primary color 5% Gurbi
//const primaryColorPercent8 = '#2B3E5314' // primary color 8% Gurbi
const primaryColorPercent10 = '#2B3E531A' // primary color 10% Gurbi
const primaryColorPercent20 = '#2B3E5333' // primary color 20% Gurbi
const primaryColorPercent30 = '#2B3E534D' // primary color 30% Gurbi
const primaryColorPercent40 = '#2B3E5366' // primary color 40% Gurbi
const primaryColorPercent50 = '#2B3E5380' // primary color 50% Gurbi
const primaryColorPercent60 = '#2B3E5399' // primary color 60% Gurbi
const primaryColorPercent90 = '#2B3E53E6' // primary color 90% Gurbi

// darkmode colors
const primaryDarkColor1 = '#D5D9DD' // text border color
const primaryDarkColor1Parcent5 = '#D5D9DD0D'
const primaryDarkColor1Parcent10 = '#D5D9DD1A'
const primaryDarkColor1Parcent20 = '#D5D9DD33'
const primaryDarkColor1Parcent40 = '#D5D9DD66' // border default
const primaryDarkColor1Parcent50 = '#D5D9DD80' // border default


const secondaryColor1 = '#FF9800' // buttons, links
//const secondaryColor1Percent15 = '#FF980026' // buttons, links
const secondaryColor1Percent40 = '#FF980066' // buttons, links
//const secondaryColor2 = '#FFA219' // hover
const secondaryColor2 = '#FFAB2E' // hover
const whiteColorParcent5 = '#FFFFFF0D' // hover
const whiteColorParcent10 = '#FFFFFF1A'
const whiteColorParcent20 = '#FFFFFF33'
const whiteColorParcent50 = '#FFFFFF80'

const turquoiseColor = '#037E8C'
const violettColor = '#AB03D3'
const lightLLMBlue = '#0073e6'
//const blueViolettColor = '#540D6E'
const lightBlueViolettColor = '#AB03D3'
const greenColor1 = '#008A00'
const greenColor2 = '#9FD400'
//const redColor1 = '#760002'
//const redColor2 = '#C21822'
const redColor3 = '#E70B04'
const redColor4 = '#E70B0433'
const redColorHover = '#EF5A5E'
const darkRed = '#BE1F2E'
const gdprBlue = '#407EC9'
const AIBlue = '#8BABF1'
const AIPurple = '#5B29ED'
const logmonitoringColor = '#00B4C5'
//const lightGdprBlue = '#8DC8E8'
const whiteColor = '#fff'
const whiteColor90 = '#ffffffE6'
//const whiteColor60 = '#ffffff99'
const darkblue = '#172739'
const yellowColor = '#FFB413'
const modalBg = '#f7f7f7'


const darkGrayColor = '#333333'
const grayColor = '#999999'
const lightGrayColor = '#eeeeee'
const backgroundGrayColor = '#cccccc'

const regressionLight = '#D5D9DD'

//table row colors
const tableRowDarkColor = primaryColor1
const tableRowLightColor = '#ebebeb'
const tableRowBorderLightColor = '#ebebeb'
const tableRowHoverDarkColor = '#243040'
const tableRowHoverLightColor = '#F1F2F4'

// form border colors
const formBorderDarkColor = '#5A6F81'
const formBorderLightColor = '#ebebeb'

/* all colors that are similar in all themes */
const commonColors = {

  primary: primaryColor1,
  primary50: primaryColorPercent50,
  primary40: primaryColorPercent40,
  primary20: primaryColorPercent20,
  primary10: primaryColorPercent10,
  primarydark: primaryDarkColor1,
  primarydarkHover: primaryDarkColor1Parcent50,
  primaryHover: primaryColor10, // 10 % lighter than primary
  backgroundDark: primaryColor9,
  warning: yellowColor,
  warningColor: yellowColor,
  danger: redColor3,
  dangerHover: redColorHover,
  dangerLight: redColor4,
  success: greenColor1,
  info: turquoiseColor,
  white: whiteColor,
  secondary: secondaryColor1,
  secondary40: secondaryColor1Percent40,
  secondaryHover: secondaryColor2, // 10 % lighter than secondary
  backgroundTabs: primaryColor7,
  backgroundBadge: primaryColor7,
  turquoiseColor: turquoiseColor, // stpes
  violettColor: violettColor,
  greenColor1: greenColor1,
  sliderBg: primaryColorPercent5,
  tableRowBg: primaryColorPercent2,
  themeswitchbg: primaryDarkColor1,
  securetext: darkRed,
  border40: primaryDarkColor1Parcent40,
  white20: whiteColorParcent20,
  llmBlue: lightLLMBlue,
  lowRisk: greenColor2,


  //finaly
  primarySecondary: secondaryColor1
}

const mainTheme = {
  colors: {
    common: commonColors
  },

  status: {
    danger: 'orange',
  },
  overrides: {

  }
  //end

}

const lightThemeColors = {

  primaryHover: primaryColor10, // 10 % lighter than primary
  cardlinkHoverLight: modalBg,
  cardlinkHoverDark: modalBg,
  backgroundPrimary: whiteColor,
  backgroundSecondary: whiteColor,
  backgroundTableRow: primaryColorPercent2,
  HelperText: primaryColorPercent50,
  orangeColor: secondaryColor1,
  backgroundChip: primaryDarkColor1,
  cardSecondaryBG: 'transparent',
  flymenubg: whiteColor,
  dividerOrbg: whiteColor,
  dividerOrbgsecoundary: whiteColor,
  switchunchecked: primaryColorPercent50,
  //listbarcolors
  barbackground: primaryColorPercent60,
  barprogress: primaryColorPercent60,
  //finaly
  primary: primaryColor1,
  primaryInverse: primaryDarkColor1,
  primary50: primaryColorPercent50,
  inputlabel: primaryColor1,
  borderColor: formBorderLightColor,
  borderColorhover: primaryColor1,
  dividerTextBg: whiteColor,
  chipbg: primaryColorPercent10,
  chipbgHover: primaryColorPercent5,
  dropdownListHover: secondaryColor1Percent40,
  dropdownListActive: secondaryColor1,
  cardfooterbg: primaryColorPercent3,
  stepslables: primaryColor1,
  stepsline: primaryColorPercent10,
  expansionBGcolor: 'transparent',
  expansionborderColor: primaryColorPercent10,
  expansionbordercontenttext: primaryColor1,
  settingiconcolor: primaryColorPercent50,
  settingiconbghover: primaryColorPercent10,
  modalBg: modalBg,
  menuBg: primaryColorPercent5,
  parametersBg: whiteColor,
  testdatawizardcardHover: primaryColorPercent20,
  testdatawizardcardBg: 'transparent',
  customListbg: primaryColorPercent5,
  menubuttonbg: whiteColor,
  menubuttontext: secondaryColor1,
  cardprogress: primaryColor1,
  testsetseditorbg: primaryColorPercent5,
  senderBotbg: primaryColor1,
  darkGray: darkGrayColor,
  gray: grayColor,
  lightGray: lightGrayColor,
  backgroundGray: backgroundGrayColor,
  darkButton: primaryColor1,
  darkButtonText: whiteColor,
  darkButtonHover: primaryColorPercent90,

  // WCAG colors
  visibleborderColor: primaryColor1,
  disabledtext: primaryColorPercent50,
  linkButtonHover: primaryColorPercent10,
  danger: redColor3,
  dangerHover: redColorHover,
  topmenuHover: primaryColorPercent10,

  // test types
  regressiontext: primaryColor1,
  nlptext: turquoiseColor,
  securetext: darkRed,
  gdprtext: gdprBlue,
  logmonitoringtext: logmonitoringColor,
  factcheckertext: AIBlue,
  misusecheckertext: AIPurple,
  performancetext: yellowColor,
  e2etext: lightBlueViolettColor,

  // GDPR sections
  gdprSecurity: darkRed,
  gdprControl: turquoiseColor,
  gdprTransparency: lightBlueViolettColor,
  gdprPrivacy: gdprBlue,

  calendarPickerindicator: 'invert(21%) sepia(5%) saturate(4363%) hue-rotate(172deg) brightness(92%) contrast(87%)',
  senderIncludeborder: primaryColorPercent60,

  tableRow: tableRowLightColor,
  tableRowHover: tableRowHoverLightColor,
  tableRowBorder: tableRowBorderLightColor
}

export const lightTheme = createMuiTheme(_.merge(mainTheme, {
    colors: lightThemeColors,
    status: {
      danger: 'orange',
    },

    overrides: {
      MuiTablePagination: {
        caption: {
          color: lightThemeColors.primary
        }
      },

    }
  }))

const darkThemeColors = {

  primaryHover: whiteColor, // 10 % lighter than primary
  cardlinkHoverLight: primaryColorPercent60,
  cardlinkHoverDark: primaryColor9,
  backgroundPrimary: primaryColor9,
  backgroundSecondary: primaryColor1,
  backgroundTableRow: primaryDarkColor1Parcent5,
  backgroundChip: primaryDarkColor1,
  cardSecondaryBG: primaryColor1,
  HelperText: primaryDarkColor1,
  flymenubg: primaryColor1,
  dividerOrbg: primaryColor9,
  dividerOrbgsecoundary: primaryColor1,
  switchunchecked: whiteColor,
  //listbarcolors
  barbackground: whiteColorParcent20,
  barprogress: whiteColorParcent20,
  //finaly
  primary: primaryDarkColor1,
  primaryInverse: primaryColor1,
  primary50: primaryDarkColor1Parcent50,
  inputlabel: primaryDarkColor1,
  borderColor: formBorderDarkColor,
  borderColorhover: primaryDarkColor1,
  dividerTextBg: primaryColor1,
  chipbg: primaryDarkColor1,
  chipbgHover: primaryDarkColor1Parcent40,
  dropdownListHover: secondaryColor1Percent40,
  dropdownListActive: secondaryColor1,
  cardfooterbg: whiteColorParcent5,
  stepslables: primaryDarkColor1,
  stepsline: primaryDarkColor1,
  expansionBGcolor: primaryColor1,
  expansionborderColor: darkblue,
  expansionbordercontenttext: primaryDarkColor1,
  settingiconcolor: primaryDarkColor1Parcent50,
  settingiconbghover: primaryDarkColor1Parcent10,
  modalBg: primaryColor1,
  menuBg: primaryColor1,
  parametersBg: darkblue,
  testdatawizardcardHover: primaryDarkColor1Parcent40,
  testdatawizardcardBg: primaryColor1,
  customListbg: primaryDarkColor1Parcent5,
  menubuttonbg: primaryDarkColor1Parcent20,
  menubuttontext: secondaryColor1,
  cardprogress: turquoiseColor,
  testsetseditorbg: primaryColorPercent30,
  senderBotbg: '#435972',
  darkGray: lightGrayColor,
  gray: grayColor,
  lightGray: darkGrayColor,
  backgroundGray: backgroundGrayColor,
  darkButton: whiteColor,
  darkButtonText: primaryColor1,
  darkButtonHover: whiteColor90,

  // WCAG colors
  visibleborderColor: whiteColor,
  disabledtext: whiteColorParcent50,
  linkButtonHover: whiteColorParcent10,
  danger: redColor3,
  dangerHover: redColorHover,
  topmenuHover: whiteColorParcent10,

  // test types
  regressiontext: regressionLight,
  nlptext: turquoiseColor,
  securetext: darkRed,
  gdprtext: gdprBlue,
  logmonitoringtext: logmonitoringColor,
  factcheckertext: AIBlue,
  misusecheckertext: AIPurple,
  performancetext: yellowColor,
  e2etext: lightBlueViolettColor,

  // GDPR sections
  gdprSecurity: darkRed,
  gdprControl: turquoiseColor,
  gdprTransparency: lightBlueViolettColor,
  gdprPrivacy: gdprBlue,

  calendarPickerindicator: 'invert(93%) sepia(9%) saturate(104%) hue-rotate(169deg) brightness(94%) contrast(89%)',
  senderIncludeborder: primaryDarkColor1Parcent40,

  tableRow: tableRowDarkColor,
  tableRowHover: tableRowHoverDarkColor,
  tableRowBorder: tableRowDarkColor
}

export const darkTheme = createMuiTheme(_.merge(mainTheme, {
  colors: darkThemeColors,
  overrides: {
    MuiTablePagination: {
      caption: {
        color: commonColors.primaryDarkColor1
      }
    },
  }
}))
