import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
// apollo
import { Mutation, compose, graphql, withApollo } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/Button/Button'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Field from 'components/Form/OptionalField'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import ShowIcon from 'components/Icon/ShowIcon'

import {
  renderTextField,
  renderSelect,
  required,
  FormActionsToolbar, renderNamespaceField, renderAutoSuggest
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { getConnector } from 'actions/settings'

import testprojectsStyle from 'assets/jss/material-dashboard-react/views/testprojectsStyle.jsx'

import { CREATE_MISUSECHECKERPROJECT, DeleteMisuseCheckerListsFromCache, RefetchMisuseCheckerProjectQueries } from './gql'
import { CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'
import { RefetchTestSetQueries } from '../TestSets/gql'
import { safeGetNamespaceFilteredList } from '../helper.jsx'

import { hasPermission } from 'botium-box-shared/security/permissions'
import GeneralTestProjectsEmbeddedTable from '../TestProjects/GeneralTestProjectsEmbeddedTable.jsx'

class MisuseCheckerProjects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newProjectExpanded: false,
      emptyMisuseCheckerProject: {
        name: 'New LLM Misuse Checker Project',
        namespace: props.namespace.selected ? props.namespace.selected.name : null,
        chatbot: null,
        distractionTopics: ['Violence', 'Drugs'],
        numberOfCycles: 2
      }
    }
  }

  renderStartForm() {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, chatbotsData } = this.props
    let startProject = true
    return (
      <Mutation
        mutation={CREATE_MISUSECHECKERPROJECT}
        refetchQueries={[
          ...RefetchMisuseCheckerProjectQueries(),
          ...RefetchTestSetQueries()
        ]}
        update={DeleteMisuseCheckerListsFromCache}
      >
        {(createMisuseCheckerProject, { loading, error }) => {
          return <Form
            onSubmit={async (values, form) => {
              try {
                const res = await createMisuseCheckerProject({
                  variables: {
                    input: {
                      name: values.name,
                      namespace: values.namespace,
                      chatbotId: values.chatbotId,
                      configuration: {
                        allowedDomains: { set: values.allowedDomains || [] },
                        approvedTopics: { set: values.approvedTopics || ['Greetings', 'Farewells'] },
                        confusedSentences: { set: values.confusedSentences || ['I am not sure about that.', 'Can you clarify?'] },
                        forbiddenTopics: { set: values.forbiddenTopics || [] },
                        ignoredSentences: { set: values.ignoredSentences || ['AI-generated content may be incorrect'] },
                        distractionTopics: { set: values.distractionTopics || []},
                        numberOfCycles: values.numberOfCycles || 1,
                      }
                    },
                    start: startProject
                  },
                })
                form.initialize(this.state.emptyMisuseCheckerProject)
                setAlertSuccessMessage('LLM Misuse Checker Project registered')
                history.push(`/misusecheck/projects/view/${res.data.createMisuseCheckerProject.id}/content`)
              } catch (error) {
                setAlertErrorMessage(`LLM Misuse Checker Project registration failed`, error)
              }
            }}
            initialValues={this.state.emptyMisuseCheckerProject}
            render={({
                       handleSubmit,
                       submitting,
                       invalid,
                       values,
                       form: { change }
                     }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem md={12} lg={4}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="LLM Misuse Checker Project Name"
                      validate={required}
                      data-unique="txtMisuseCheckerProjectRegisterName"
                    />
                  </GridItem>
                  <GridItem md={12} lg={4}>
                    <Field
                      name="namespace"
                      component={renderNamespaceField}
                      forWrite
                      label="Namespace"
                      data-unique="txtMisuseCheckerProjectNamespace"
                    />
                  </GridItem>
                  <GridItem md={12} lg={4}>
                    <Field
                      name="chatbotId"
                      component={renderSelect}
                      label="Select a Chatbot"
                      data-unique={`selMisuseCheckerProjectRegisterChatbot`}
                      validate={required}
                      items={chatbotsData && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace).map(c => {
                        return {
                          key: c.id,
                          chatbot: c
                        }
                      })
                      }
                    />
                  </GridItem>
                  <GridItem md={12} lg={4}>
                    <Field
                      name="allowedDomains"
                      component={renderAutoSuggest}
                      label="Chatbot Domains"
                      validate={required}
                      helperText="Defines the primary domain(s) in which the Target Bot is expected to operate, ensuring it maintains focus within its designated areas."
                      data-unique="txtMisuseCheckerProjectAllowedDomains"
                    />
                  </GridItem>
                  <GridItem md={12} lg={4}>
                    <Field
                      name="distractionTopics"
                      component={renderAutoSuggest}
                      label="Distraction Topics"
                      validate={required}
                      helperText="A list of off-topic subjects designed to test the bot’s ability to maintain focus within its designated domain."
                      data-unique="txtMisuseCheckerProjectDistrationTopics"
                    />
                  </GridItem>
                  <GridItem md={12} lg={4}>
                    <Field
                      name="numberOfCycles"
                      component={renderSelect}
                      label="Misuse Attempts"
                      data-unique={`selMisuseCheckerProjectNumberOfCycles`}
                      validate={required}
                      items={[
                        { key: 1, label: 'Short' },
                        { key: 2, label: 'Standard' },
                        { key: 3, label: 'Extended' },
                        { key: 4, label: 'Exhaustive' }
                      ]}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <FormActionsToolbar
                      rightButtons={<>
                        <Button
                          secondary
                          disabled={submitting}
                          onClick={() => this.setState({ newProjectExpanded: false })}
                          data-unique="btnTestProjectRegisterCancel"
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={submitting || invalid}
                          data-unique="btnMisuseCheckerProjectRegisterSave"
                          onClick={() => {
                            startProject = false
                            handleSubmit()
                            this.setState({ newProjectExpanded: false })
                          }}
                        >
                          {submitting && <LoadingIndicator alt />}
                          Save
                        </Button>
                        <Button
                          disabled={submitting || invalid}
                          data-unique="btnMisuseCheckerProjectRegisterSaveAndStart"
                          onClick={() => {
                            startProject = true
                            handleSubmit()
                            this.setState({ newProjectExpanded: false })
                          }}
                        >
                          {submitting && <LoadingIndicator alt />}
                          Start
                        </Button>
                      </>}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
          }
        }
      </Mutation>
    )
  }

  render() {
    if (this.state.newProjectExpanded) {
      return this.renderStartForm()
    }

    return this.renderTableMode()
  }

  renderTableMode() {
    const { user, classes } = this.props
    return (
      <GridContainer noPadding>
        <GridItem xs={12} right>
          <Button noMargin
                  onClick={() => this.setState({ newProjectExpanded: true })}
                  data-unique="btnQuickstartGoToTestProjects"
                  disabled={!hasPermission(user, 'TESTPROJECTS_CREATE')}
          >
            <ShowIcon icon="plus" />
            Create Test
          </Button>
        </GridItem>
        <GridItem xs={12} className={classes.projectsListEmbeddedTable}>
          <GeneralTestProjectsEmbeddedTable projectType="misusechecker" name="MisuseCheckerProjects" disableOrderBy disableFooter={false}  />
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(testprojectsStyle),
  connect(
    state => ({ user: state.token.user, settings: state.settings, license: state.settings.license, namespace: state.namespace }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  }),
  withApollo
)(MisuseCheckerProjects)
