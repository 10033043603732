import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons

// core components
import cardBodyStyle from 'assets/jss/material-dashboard-react/components/cardBodyStyle.jsx'

function CardBody({ ...props }) {
  const { classes, className, children, plain, profile, noPadding, noPaddingBottom, topPadding, bottomPadding, noPaddingTop, smallPadding, minimumHeight, LargePadding, LargerPadding, cursorPointer,  ...rest } = props
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined,
    [classes.cardBodyNoPadding]: noPadding,
    [classes.cardBodyNoPaddingTop]: noPaddingTop,
    [classes.cardBodyNoPaddingBottom]: noPaddingBottom,
    [classes.cardBodySmallPadding]: smallPadding,
    [classes.cardBodyLargePadding]: LargerPadding,
    [classes.cardBodyLargerPadding]: LargerPadding,
    [classes.CardBodytopPadding]: topPadding,
    [classes.CardBodybottomPadding]: bottomPadding,
    [classes.cardBodyminimumHeight]: minimumHeight,
    [classes.cursorPointer]: cursorPointer,
  })
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  )
}

CardBody.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  smallPadding: PropTypes.bool,
  minimumHeight: PropTypes.bool,
  topPadding: PropTypes.bool,
  bottomPadding: PropTypes.bool,
}

export default withStyles(cardBodyStyle)(CardBody)
