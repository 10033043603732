export const projectTypeFlagsToConnectorFeature = (projectFlags) => {
  let connectorFeatureRequired
  let connectorFeatureExcluded
  if (projectFlags.e2eTesting) {
    connectorFeatureRequired = 'e2eTesting'
  } else if (projectFlags.nlpAnalytics) {
    connectorFeatureRequired = 'intentResolution'
  } else if (projectFlags.securityCheck) {
    connectorFeatureRequired = 'securityTesting'
  } else if (projectFlags.regressionTesting) {
    connectorFeatureRequired = 'conversationFlowTesting'
  }

  // teoretically those flags can't be together, but to be sure we check it
  if (projectFlags.performanceTesting && !projectFlags.e2eTesting) {
    connectorFeatureExcluded = 'e2eTesting'
  }

  return { connectorFeatureRequired, connectorFeatureExcluded }
}

export const urlToProjectTypeFlags = (url) => {
  return {
    nlpAnalytics: url.startsWith('/nlp') || undefined,
    securityCheck: url.startsWith('/security') || undefined,
    performanceTesting: url.startsWith('/performance') || undefined,
    gdprTesting: url.startsWith('/gdpr') || undefined,
    e2eTesting: url.startsWith('/e2e') || undefined,
    regressionTesting: url.startsWith('/regression') || undefined,
    monitoring: url.startsWith('/scheduledtests') || undefined
  }
}

export const projectToProjectTypeFlags = (project) => {
  return {
    nlpAnalytics: project.nlpAnalytics,
    securityCheck: project.securityCheck,
    performanceTesting: project.performanceTesting,
    gdprTesting: project.gdprTesting,
    e2eTesting: project.e2eTesting,
    regressionTesting: project.regressionTesting,
    monitoring: project.monitoring
  }
}

export const urlToTestTypeFilter = (url) => {
  const testTypes = ['MONITORING', 'NLP', 'REGRESSION', 'SECURITY', 'PERFORMANCE', 'E2E', 'GDPR', 'FACTCHECKER', 'MISUSECHECKER']

  const raw = url.split('/').filter(p => p)[0]?.toUpperCase()
  if (raw === 'FACTCHECK') {
    return 'FACTCHECKER'
  }

  if (raw === 'MISUSECHECK') {
    return 'MISUSECHECKER'
  }

  if (testTypes.includes(raw)) {
    return raw
  }

  // It would be better to call this function always with URL which has a testtype. So just from a project not from a testset for example.
  // In that case this could be an error.
  console.log(`Url ${url} (${raw}) is not bound to any test type`)

  return null
}

export const PROJECT_TYPE_URLS = ['e2e', 'performance', 'nlp', 'security', 'regression', 'gdpr', 'scheduledtests']

export const projectTypeFlagsToUrl = (projectFlags) => {
  // project can have more types, so order is important. But it order does not looks critical.
  if (projectFlags.e2eTesting) {
    return '/e2e'
  }
  if (projectFlags.performanceTesting) {
    return '/performance'
  }
  if (projectFlags.nlpAnalytics) {
    return '/nlp'
  }
  if (projectFlags.securityCheck) {
    return '/security'
  }
  if (projectFlags.regressionTesting) {
    return '/regression'
  }
  if (projectFlags.gdprTesting) {
    return '/gdpr'
  }
  if (projectFlags.monitoring) {
    return '/scheduledtests'
  }
  // project flags can come from test session, but there is no regression and e2e flag there.
  return false
}

export const performanceTestTypes = {
  PERFORMANCE_TEST_MODE_STRESS_TEST: 'stress',
  PERFORMANCE_TEST_MODE_LOAD_TEST: 'load',
  PERFORMANCE_TEST_MODE_ADVANCED_TEST: 'advanced'
}
